export default [
    {
        header: "menu.actions.label",
        icon: "TargetIcon",
        children: [
            {
                title: "menu.actions.list",
                icon: "ListIcon",
                route: "list-actions",
                action: "list",
                resource: "actions",
            },
        ],
    },
]
