export default [
    {
        header: "menu.management",
        icon: "SettingsIcon",
        children: [
            {
                title: "menu.systemSettings",
                route: "list-system-settings",
                icon: "SlidersIcon",
                action: "manage",
                resource: "system-settings",
            },
            {
                title: "menu.users",
                route: "list-users",
                icon: "UsersIcon",
                action: "list",
                resource: "users",
            },
            {
                title: "menu.roles",
                route: "list-roles",
                icon: "LockIcon",
                action: "list",
                resource: "roles",
            },
            {
                title: "menu.systemRecords",
                route: "list-available-system-records",
                icon: "ListIcon",
                action: "list",
                resource: "system-records",
            },
        ],
    },
]
