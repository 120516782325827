import Vue from "vue"

export default [
    {
        header: "menu.contacts.label",
        icon: "UsersIcon",
        children: [
            {
                title: "menu.contacts.new",
                icon: "UserIcon",
                route: null,
                onClick: () => {
                    Vue.prototype.$createPeopleModal.show()
                },
                action: "create",
                resource: "people",
            },
            {
                title: "menu.contacts.people",
                icon: "UsersIcon",
                route: "list-people",
                action: "list",
                resource: "people",
            },
            {
                title: "menu.companies.label",
                icon: "BriefcaseIcon",
                children: [
                    {
                        title: "menu.companies.new",
                        icon: "PlusIcon",
                        route: null,
                        onClick: () => {
                            Vue.prototype.$createCompaniesModal.show()
                        },
                        action: "create",
                        resource: "companies",
                    },
                    {
                        title: "menu.companies.list",
                        icon: "ListIcon",
                        route: "list-companies",
                        action: "list",
                        resource: "companies",
                    },
                ],
                action: "list",
                resource: "companies",
            },
        ],
    },
]
